import React from "react";
import Layout from "../components/Layout/layout";
import EducationItems from "../components/EducationItems/educationItems";
import { graphql, useStaticQuery } from 'gatsby'
import '../components/page-styles/pages.css';
/*Component*/

const Education = () => {
  const data = useStaticQuery(graphql`
  query {
    contentfulEducationPageIntro {
      title
      description {
        description
      }
    }
  }
  `)
  const {title, description} = data.contentfulEducationPageIntro
  return (
    <Layout>
      <div className="text-wrapper">
          <h1 className="title">{title}</h1>
          <p className="paragraph">{description.description}</p> 
      </div>
      <EducationItems/>
    </Layout>
)};

export default Education;
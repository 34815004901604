import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import {EduItemsWrapper, EduItem, Image} from "../styles";

/*Component*/

const EducationItems = () => {
    const data = useStaticQuery(graphql`
    query {
      allContentfulEducation(sort: { fields: date, order: DESC }) {
            edges{ 
                node{
                    date
                    image {
                    fluid(maxWidth: 600) {
                        aspectRatio
                        base64
                        sizes
                        src
                        srcSet
                        srcSetWebp
                        srcWebp
                        tracedSVG
                        }
                    }
                }
            }
        }
      }
    `)
return (
    <EduItemsWrapper role="presentation">
     {data.allContentfulEducation.edges.map((edge) => {
            const { image, id} = edge.node        
            return (
              <EduItem key={id} >
                  <Img fluid={image.fluid} alt={image.title} style={Image} />
              </EduItem>
            )})}
    </EduItemsWrapper>
)}

export default EducationItems;
